.modalCard{
    width: 632px !important;
    border: 1px solid #F5F6F7;
    box-shadow: 0px 0px 128px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
    background-color: #fff;
    color: #000000;
    padding: 0px;
    margin: auto;
    margin-top: 8px;
    display: block;
}
.modalCard form{
    height: 65vh;
    overflow: auto;
}
@media screen and (max-width:500px){
    .modalCard{
        width: 98% !important;
        border: 1px solid #F5F6F7;
        box-shadow: 0px 0px 128px rgba(0, 0, 0, 0.02);
        border-radius: 6px;
        background-color: #fff;
        color: #000000;
        padding: 0px;
        margin-top: 10px;
        margin-left: 3px;
    }
    .modalCard form{
        height: 70vh;
        overflow: auto;
    }
}