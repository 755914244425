.createAccount{
    padding: 32px;
    padding-bottom: 0px;
}
.marginForm{
    margin: 32px;
}
@media screen and (max-width:500px){
    .displayNone{
        display: none;
    }
    .hideShadow div:first-child{
        box-shadow: none;
        border: none;
    }
    .createAccount{
        padding: 27px 0 0 0;
    }
    .marginForm{
        margin: 0 0 18px 0;
    }
}