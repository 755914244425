.formStyle{
    padding: 32px;
    border: 0px solid;
    box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.04);
}
@media screen and (max-width:500px){
    .formStyle{
        padding:8px 0 0 0;
        margin-top: 14px;
    }
}