@media screen and (max-width:500px){
    .guides{
        background-color: #EEEFF0;
        border-radius:16px;
        padding: 16px 0px;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .paddingStyle{
        padding-top: 31px;
        padding-bottom: 31px;
    }
}