@media screen and (max-width:500px){
    .office_hours{
        padding-top: 30px;
        justify-content: center;
        gap: 48px;
        align-items: center;
    }
    .office_hours div:first-child{
        padding: 0 0 0 0;
        box-shadow: none;
    }
    .all_times{
        padding-bottom: 50px;
    }
}