.buttons_education{
    padding-top: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    margin-bottom: -12px;
}
@media screen and (max-width:500px){
    .buttons_education{
        position: sticky;
        padding-top: 12px;
        background-color: white;
        z-index: 8;
        bottom: 0;
        display: flex;
        flex-flow: column-reverse;
        gap: 8px;
    }
    .buttons_education button{
        width: 100%
    }
}