.clients_served{
    box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    padding: 48px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border: 0px solid;
}
.buttons_education{
    padding-top: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    margin-bottom: -12px;
}
@media screen and (max-width:500px){
    .clients_served{
        padding: 48px 0px;
        box-shadow: none;
    }
    .buttons_education{
        position: sticky;
        padding-top: 12px;
        background-color: white;
        z-index: 8;
        bottom: 0;
        display: flex;
        flex-flow: column-reverse;
        gap: 8px;
    }
    .buttons_education button{
        width: 100%
    }
}