.practiceForm{
    margin-top:1rem;
    padding:2rem;
    box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.04);
    border: 0;
}
@media screen and (max-width:500px){
    .practiceForm{
        margin-top:1rem;
        padding:1rem 0rem;
        box-shadow:none;
        border: 0;
    }
}