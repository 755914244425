.btnSelect{
    display: none !important;
}
.bestPrice{
    text-align: center;
    padding-right: 12px;
    padding-top: 40px;
    padding-bottom: 12px;
}
@media screen and (max-width:500px){
    .gridItem{
        background-color: white !important;
        border-left: none !important;
        border-right: none !important;
    }
    .textCenter{
        text-align: center;
    }
    .displayNone{
        display: none !important;
    }
    .textSmall{
        text-align: center;
    }
    .textSmall h2{
        font-size: 23px;
    }
    .btnSelect{
        display: block !important;
        margin-top: 12px !important;
    }
    .pricingBox{
        background-color: #F2F5F7;
        border-radius: 5px;
        padding: 12px 8px;
        min-height: 154px;
    }
    .pricingBox h4{
        font-size: 16px;
        margin-bottom: 10px;
        min-height: 56px;
    }
    .bestPrice{
        padding-top: 0px;
    }
    .membershipText{
        width: 100%;
        text-align: center;
    }
    .membershipText h4{
        margin-bottom: 0px;
    }
    .membershipText p{
        margin-top: 12px;
    }
}