.stickyButton{
    padding-top: 2rem;
}
@media screen and (max-width:500px){
    .stickyButton{
        position: sticky;
        bottom: 0;
        background-color: white;
        padding: 2rem 0rem;
        z-index: 8;

    }
    .stickyButton button{
        width: 100%;
    }
}