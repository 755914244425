.layoutForm{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 50px;
}
.styleHeader{
    width: 100%;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 9;
}
.paddingLarge{
    padding-top: 40px;
}
.hideLg{
    display: none;
}
@media screen and (max-width:500px){
    .layoutForm{
        padding-left: 0;
        padding-top: 14px;
    }
    .shadowLayout{
        box-shadow: 0px 0px 1px 0px rgba(66, 71, 76, 0.48), 0px 4px 8px 0px rgba(66, 71, 76, 0.06), 0px 8px 48px 0px #EEE;
    }
    .paddingLarge{
        padding-top: 0px;
    }
    .hideLg{
        display: block;
    }
    .hideSmall{
        display: none;
    }
}