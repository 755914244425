.cardShadow{
    padding:2rem;
    box-shadow: 0px 0px 64px rgba(0, 0, 0, 0.04);
    border: 0px solid;
}
@media screen and (max-width:500px){
    .cardShadow{
        box-shadow: none;
        padding: 2rem 0rem;
    }
}