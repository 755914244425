.alignmentText{
    text-align: center;
}
@media screen and (max-width:500px){
    .alignmentText{
        text-align: start;
        padding-left: 1rem;
        padding-top: 27px;
    }
    .onboard-progress-bar{
        display: flex;
        width: 200px;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
    }
    .active-onboard-progress-bar-item{
        width:27px;
        height:3px;
        background-color:#0C8CE9;
    }
    .onboard-progress-bar-item{
        width:27px;
        height:3px;
        background-color:#EEEFF0;
    }
}
