.degree_style {
  display: grid;
  grid-template-columns: auto 1fr;
  border-width: 1px;
  border-style: solid;
  border-color: #f1f2f3;
  padding: 24px;
  border-radius: 8px;
}
.degree_style_inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  text-align: center;
  h5 {
    text-wrap: nowrap;
  }
}
@media screen and (max-width: 500px) {
  .degree_style {
    grid-template-columns: 1fr 1fr;
    border: none;
    padding: 0px;
  }
  .degree_style_inner {
    grid-template-columns: 1fr;
  }
}
