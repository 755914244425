.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-1cuv9r0-MuiBadge-badge, .MuiBadge-badge {
  background-color: #d85442;
  color: #ffffff !important;
  font-size: 10px !important;
}

.Notifcation-tabs:hover,
.notification-row:hover,
.profile-mail:hover,
.dummy-search-input:hover,
.payment-card:hover,
.view-profile-text:hover {
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .dummy-search-input {
    display: none !important;
  }
  .menu-icon-dropdown {
    display: none !important;
  }
  .css-1hrfd28-MuiFormControl-root-MuiTextField-root input:not(textarea) {
    font-size: 16px !important;
  }
  .MuiBox-root .css-1bb7uu3 {
    border: none !important;
  }

  .css-y531zm-MuiPaper-root, .MuiPaper-root {
    border: none !important;
  }
  .MuiFormControl-root-MuiTextField-root input:not(textarea), .css-1vow3vj-MuiFormControl-root-MuiTextField-root input:not(textarea) {
    padding: 20px !important;
    font-size: 16px !important;
  }
  .css-i3pbo {
    margin-bottom: 0 !important;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .btnContinue{
    width: 92%;
  }
  .MuiModal-root .MuiPaper-root:not(.MuiAlert-root), .css-3pb0lv-MuiModal-root-MuiDrawer-root .MuiPaper-root:not(.MuiAlert-root) {
    width: 100vw !important;
  }
  
}
.tags-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tags-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.MuiPaper-root, .css-i6i50p-MuiPaper-root {
  box-shadow: 0px 0px 1px 0px rgba(66, 71, 76, 0.48),
    0px 4px 8px 0px rgba(66, 71, 76, 0.06), 0px 8px 48px 0px #eee !important;
}

.payment-guid-container {
  background: linear-gradient(180deg, #fff 0%, #edfaff 100%), #fff;
}

.menu-Item-header {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  margin-left: 8px !important;
}

.MuiButton-startIcon {
  display: inherit;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.MuiInputBase-input-MuiOutlinedInput-input:not(textarea), .css-1fkf7o5-MuiInputBase-input-MuiOutlinedInput-input:not(textarea) {
  padding: 0px 8px !important;
  height: 65px;
  /* padding: 21.5px 16px !important; */
}
.menu {
  width: "100%";
}
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0);
  transition: all 0.25s;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-300%);
  transition: all 0.25s;
}

.menu-insurence-enter,
.menu-ClientTypes-enter,
.menu-Specialities-enter,
.menu-Treatement-enter .menu-Availability-enter,
.menu-Demographics-enter,
.menu-degree-enter,
.menu-languages-enter,
.menu-ClientsServed-enter,
.menu-training-enter,
.menu-credentials-enter,
.menu-credentials-enter,
.menu-fees-enter {
  position: absolute;
  transform: translateX(110%);
}

.menu-insurence-enter-active,
.menu-ClientTypes-enter-active,
.menu-Specialities-enter-active,
.menu-Treatement-enter-active .menu-Availability-enter-active,
.menu-Demographics-enter-active,
.menu-degree-enter-active,
.menu-languages-enter-active,
.menu-ClientsServed-enter-active,
.menu-training-enter-active,
.menu-credentials-enter-active,
.menu-fees-enter-active {
  transform: translateX(0);
  transition: all 0.25s;
}

.menu-insurence-exit,
.menu-ClientTypes-exit,
.menu-Specialities-exit,
.menu-Treatement-exit .menu-Availability-exit,
.menu-Demographics-exit,
.menu-degree-exit,
.menu-languages-exit,
.menu-ClientsServed-exit,
.menu-training-exit,
.menu-credentials-exit,
.menu-fees-exist {
  position: absolute;
}
.menu-insurence-exit-active,
.menu-ClientTypes-exit-active,
.menu-Specialities-exit-active,
.menu-Treatement-exit-active .menu-Availability-exit-active,
.menu-Demographics-exit-active,
.menu-degree-exit-active,
.menu-languages-exit-active,
.menu-ClientsServed-exit-active,
.menu-training-exit-active,
.menu-credentials-exit-active,
.menu-fees-exit-active {
  transform: translateX(300%);
  transition: all 0.25s;
}


.input::placeholder {
  margin-bottom: 2px;
}

 .flexDays-onbord, .flexHours{
  display: flex;
  gap:12px;
}
.flexDays, .flexHours{
  display: flex;
  gap:12px;
  align-items: center;
  flex-flow: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.flexHours button{
  flex: 16% 0;
  width: 90px;
}
@media screen and (max-width:500px){
  .flexDays,.flexHours{
    flex-flow: wrap;
  }
  .flexDays button{
    flex: 16% 0;
  }
  .flexHours button{
    flex: 16% 0;
    width: 90px;
  }
}

a {
  text-decoration: none !important;
}